import { TitleForm, GenericTitle, RecapContainer, WrapperRecap } from "./style";
import RecapCardsList from "./recapCardsList/RecapCardsList";
import { ButtonsCta } from "./buttonsCta/buttonsCta";
import { useSelector } from "react-redux";
import { selectRecapPage } from "../../../../../pages/SimpleEventsSubscribeForm/selectors";

export function LayoutSimpleEventsRecap({ events }) {
  const data = useSelector(selectRecapPage());
  const userSubscribedEvents = useSelector(
    (state) => state.SimpleEventsSubscribeForm.defaultBodyPayload.events
  );

  function generateHtml(argument) {
    return { __html: argument };
  }

  const getOrdereSubscriptionsDetails = () => {
    let subs = [];
    events.forEach((event) => {
      event.appointments.forEach((appointment) => {
        if (
          userSubscribedEvents.find(
            (subscription) => +subscription.appointmentId === appointment.id
          )
        ) {
          subs.push({
            ...appointment,
            eventTitle: event?.title,
            title: appointment?.title,
            pickSlotTitle: event?.pickSlotTitle,
          });
        }
      });
    });
    return subs;
  };

  const subscriptions = getOrdereSubscriptionsDetails();
  return (
    <WrapperRecap>
      <GenericTitle>
        <h5>{data?.title}</h5>
      </GenericTitle>

      <RecapContainer>
        {data?.description && (
          <GenericTitle>
            <p dangerouslySetInnerHTML={generateHtml(data?.description)} />
          </GenericTitle>
        )}
        {data?.detailTitle && <TitleForm>{data?.detailTitle}</TitleForm>}
      </RecapContainer>
      <RecapContainer>
        <RecapCardsList data={data} subscriptions={subscriptions} />
      </RecapContainer>
      <ButtonsCta data={data?.buttons} />
    </WrapperRecap>
  );
}
