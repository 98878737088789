import React, { useRef, useState } from "react";
import {
  AltText,
  Caption,
  PlayTextContainer,
  VideoPlayerWrapper,
} from "./style";

const VideoPlayer = ({ data, id }) => {
  const [isPaused, setIsPaused] = useState(true);
  const videoElement = useRef(null);

  return (
    <VideoPlayerWrapper>
      <video
        onPlaying={() => setIsPaused(false)}
        onPause={() => setIsPaused(true)}
        poster={data?.fileReferenceThumbnail || data?.thumbnail?.fileReference}
        className={`videoComp-${id}`}
        ref={videoElement}
        controls="controls"
      >
        <source src={data?.originalDamPath} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </VideoPlayerWrapper>
  );
};

export default React.memo(VideoPlayer);
