// hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchComponents } from "./actions";

// components
import DetailsHeader from "../../components/UI/event/eventDetails/header/DetailsHeader";
import Layout from "../../components/UI/event/eventDetails/Layout";
import TwoColumnContainer from "../../components/UI/event/eventDetails/twoColumnContainer/TwoColumnContainer";
import LeftColumnContainer from "../../components/UI/twoThirdTemplate/leftColumnContainer/LeftColumnContainer";
import RightColumnContainer from "../../components/UI/twoThirdTemplate/rightColumnContainer/RightColumnContainer";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// selectors
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import {
  heroComponent,
  selectLeftComponents,
  selectRightComponents,
  selectTopComponents,
} from "./selectors";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function TwoThirdTemplate({ path, type }) {
  const dispatch = useDispatch();
  const components = useSelector((state) => {
    return state.twoThird.components;
  });
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("twoThird");
  const topComponents = useSelector(selectTopComponents());
  const rightComponents = useSelector(selectRightComponents());
  const leftComponents = useSelector(selectLeftComponents());
  const heroComp = useSelector(heroComponent());
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <>
          {components?.components && (
            <>
              <DetailsHeader
                headerComponents={topComponents}
                goBackText={heroComp?.data.textHref || "TORNA INDIETRO"}
                link={heroComp?.data.link || ""}
                onlyBack={true}
              />
              <Layout>
                <TwoColumnContainer gap="32px" flexDirection="column">
                  <LeftColumnContainer
                    leftColumnComponents={leftComponents}
                    template={type}
                  />
                  <RightColumnContainer
                    rightColumnComponents={rightComponents}
                  />
                </TwoColumnContainer>
              </Layout>
            </>
          )}
        </>
      )}
    </>
  );
}

export default TwoThirdTemplate;
