import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { fetchTakeAwayComponents } from "./actions";
// components
import LayoutMensa from "../../components/UI/mensa/LayoutMensa";
import CafeteriaDetails from "../../components/UI/mensa/cafeteriaDetails/CafeteriaDetails";
import TitleSection from "../../components/UI/mensa/titleSection/TitleSection";
import CafeteriaBasketSection from "../../components/UI/mensa/cafeteriaBasketSection/CafeteriaBasketSection";
import GenericError from "../GenericErrorPage";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function TakeAway({ path }) {
  const dispatch = useDispatch();
  const { takeAwayComponents, baskets } = useSelector(
    (state) => state.takeAway
  );

  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const { status, errorData } = GetStatusAndErrorData("takeAway");

  useMemo(() => {
    path && token && dispatch(fetchTakeAwayComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const componentsMapping = {
    "fi-title": TitleSection,
    "fi-cafeteria-info": CafeteriaDetails,
    "fi-cafeteria-basket": CafeteriaBasketSection,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        takeAwayComponents?.components && (
          <LayoutMensa
            heroImg={`${takeAwayComponents?.settings?.heroImage}:hero-img?$DEFAULT$`}
          >
            {takeAwayComponents?.components.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                    baskets={baskets}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutMensa>
        )
      )}
    </>
  );
}

export default TakeAway;
