import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 50px;

  :hover {
    .carousel.carousel-slider {
      .carousel-status {
        opacity: 1;
      }
    }
    .rightArrowSlider,
    .leftArrowSlider {
      ${(props) =>
        props.hideArrows === true
          ? `display: none; opacity: 1;`
          : `display: block; opacity: 1;`};
    }
    .rightArrowSlider {
      ${(props) =>
        props.hideRightArrow === true
          ? `display: none; opacity: 1;`
          : `display: block; opacity: 1;`};
    }
    .leftArrowSlider {
      ${(props) =>
        props.hideLeftArrow === true
          ? `display: none; opacity: 0;`
          : `display: block; opacity: 1;`};
    }
    .carousel .control-dots {
      opacity: 1;
    }
    .control-next,
    .control-prev {
      z-index: 3;
      opacity: 1 !important;
    }
    @media (min-width: 1279px) {
      .control-next,
      .control-prev {
        opacity: 1 !important;
      }
    }
  }

  .carousel.carousel-slider .control-arrow {
    background: black;
  }
  ul.slider li.slide > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .carousel .control-dots {
    width: 80%;
    margin: 0 0 0 14;
    ${(props) => (props.isMobile ? `display: none;` : `display: flex;`)};
    justify-content: flex-start;
    background: #242424e8;
    z-index: 5;
    ${(props) => (props.firstHover === true ? `opacity: 0;` : `opacity: 1;`)};
    align-items: center;
    box-sizing: border-box;
    gap: 13px;
    bottom: 32px;
  }

  .carousel .slide {
    z-index: 0 !important;
  }
  .carousel .selected {
    z-index: 2 !important;
  }
  .carousel .control-dots svg.icon {
    display: none;
  }
  .carousel.carousel-slider {
    .carousel-status {
      position: absolute;
      bottom: 10px;
      top: unset;
      left: 5px;
      padding: 5px;
      font-size: 10px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
      color: #fff;
      font-family: ferrari-sans-regular, sans-serif;
      z-index: 4;
      ${(props) => (props.firstHover === true ? `opacity: 0;` : `opacity: 1;`)};
    }
  }
  @media (max-width: 1279px) {
    .carousel.carousel-slider .control-arrow {
      background: black;
    }
    padding-bottom: 50px;
    .carousel.carousel-slider {
      bottom: 0;
      overflow: initial;
    }
    .carousel .control-dots {
      overflow: hidden;

      ${(props) => (props.isMobile ? `display: none;` : `display: flex;`)};
      width: 80%;
      margin: 0 0 0 14%;
      background: #242424e8;
      bottom: 10px;
      z-index: 5;
    }
    .slider {
      transform: none !important;
    }
  }
  @media (min-width: 1280px) {
    width: 100%;
    .carousel.carousel-slider .control-arrow {
      background: black;
    }
    .carousel .control-dots {
      overflow: hidden;

      ${(props) => (props.isMobile ? `display: none;` : `display: flex;`)};

      width: 80%;
      margin: 0 0 0 14%;
      background: #242424e8;
      z-index: 5;
      bottom: 10px;
    }
    .carousel-custom-controllers {
      bottom: 32px;
    }
    .carousel-custom-controllers path {
      fill: #fff !important;
    }
  }
`;

export const DivSelectedDots = styled.div`
  width: 60px;
  height: 40px;
  display: flex;

  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    border: 1px solid red;
    box-shadow: 0 4px 8px rgba(255, 0, 0, 0.4);
  }
`;
export const ImgDots = styled.img`
  width: 60px !important;
  height: 35px;
  border-radius: 8px;
`;
export const DivUnselectedDots = styled.div`
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1; // Cambia l'opacità del div stesso
  }
  &:hover img {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(255, 0, 0, 0.4);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
`;

export const SvgArrowRight = styled.svg.attrs({
  width: "24",
  height: "24",
  viewBox: "0 0 13.7 21.9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  fill: #d4d2d2;
  position: absolute;
  right: 17px;
  bottom: 70px;
  width: 20px;
  border: none;
  z-index: 6;
  enable-background: new 0 0 13.7 21.9;
  cursor: pointer;
  opacity: 0;
  padding: 1px;
  :hover {
    fill: #da291c;
  }
`;

export const SvgArrowRightPath = styled.path.attrs({
  fillRule: "evenodd",
  d: "M13.5,10.9L2.6,0.2L0.2,2.6l8.6,8.4l-8.6,8.4l2.4,2.4L13.5,10.9",
})``;
export const SvgArrowLeft = styled.svg.attrs({
  width: "24",
  height: "24",
  viewBox: "0 0 13.7 21.9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  fill: #d4d2d2;
  position: absolute;
  ${(props) => (props.fullScreen === true ? ` left: 70px;` : ` left: 60px;`)};
  bottom: 70px;
  width: 20px;
  border: none;
  z-index: 6;
  enable-background: new 0 0 13.7 21.9;
  cursor: pointer;
  padding: 1px;
  opacity: 0;
  :hover {
    fill: #da291c;
  }
`;

export const SvgArrowLeftPath = styled.path.attrs({
  fillRule: "evenodd",
  d: "M0.2,10.9l10.9,10.7l2.4-2.4l-8.6-8.4l8.6-8.4l-2.4-2.4L0.2,10.9",
})``;
