// components
import React from "react";
import CarouselWrapper from "../../carouselWrapper/CarouselWrapper";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";

// style
import {
  Wrapper,
  Text1,
  Text2WrapperWrapper,
  Text2Wrapper,
  Frame,
  Text2Title,
  Gradient1,
  Gradient2,
  Chapter,
} from "./style";
import linkTarget from "../../../../utils/linkTarget";
import Image from "../../Image/Image";
import SmartCropVideoViewer from "../../videoPlayerSelector/smartCropVideoViewer/smartCropVideoViewer";

function MainCarousel({ data, template }) {
  const NEWS_IMG_KEY = "CAROUSEL-IMG-";
  const VIDEO_KEY = "VIDEO";

  const getIntervals = () => {
    let result = [];
    data?.items?.forEach((v) => {
      v.countDown ? result.push(parseInt(v.countDown)) : result.push(6000);
    });
    return result;
  };

  return (
    <>
      {data?.items?.length && (
        <Wrapper>
          <CarouselWrapper
            itemsLength={data?.items?.length}
            intervals={getIntervals()}
          >
            {React.Children.toArray(
              data?.items?.map((item, ind) => (
                <div className={"containerSlide"} key={`item-${ind}`}>
                  {item.cardType === "page" ? (
                    <div>
                      <a
                        href={SetQueryStringPath(item?.linkHref) || "/news"}
                        target={linkTarget(item?.linkHref)}
                      >
                        {item.section && (
                          <Text1>
                            <span>⏤</span>
                            <span>{item.section}</span>
                          </Text1>
                        )}
                        <Text2WrapperWrapper>
                          <Text2Wrapper className="home-text-animation">
                            <Chapter>
                              <div>{item?.dateLabel}</div>
                              {item?.dateLabel && <Frame></Frame>}
                              <div>{item?.category}</div>
                            </Chapter>
                            <Text2Title>{item.title}</Text2Title>
                          </Text2Wrapper>
                        </Text2WrapperWrapper>
                        <Gradient1></Gradient1>
                        <Gradient2></Gradient2>
                        <Image
                          key={`${NEWS_IMG_KEY}-${ind}`}
                          data={item}
                          injectedSmartCrop="main-carousel-web"
                          template={template}
                        />
                      </a>
                    </div>
                  ) : (
                    <div className="video-slide">
                      {item.section && (
                        <Text1>
                          <span>⏤</span>
                          <span>{item.section}</span>
                        </Text1>
                      )}
                      <Text2WrapperWrapper>
                        <Text2Wrapper className="home-text-animation">
                          <Chapter>
                            <div>{item?.dateLabel}</div>
                            {item?.dateLabel && <Frame></Frame>}
                            <div>{item?.category}</div>
                          </Chapter>
                          <Text2Title>{item.title}</Text2Title>
                        </Text2Wrapper>
                      </Text2WrapperWrapper>
                      <Gradient1></Gradient1>
                      <Gradient2></Gradient2>
                      <SmartCropVideoViewer
                        s7VideoConfig={data.s7VideoConfig}
                        key={`${VIDEO_KEY}-${ind}`}
                        id={ind}
                        data={item}
                      />
                    </div>
                  )}
                </div>
              ))
            )}
          </CarouselWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default MainCarousel;
