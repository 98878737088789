//hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchComponents } from "./FAQDetailSlice";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
// components
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import LayoutFAQDetail from "../../components/UI/FAQ/LayoutFAQDetail";
import { heroComponent } from "./selectors";
import DetailsHeader from "../../components/UI/event/eventDetails/header/DetailsHeader";
import { selectTopComponents } from "./selectors";
import { selectAggregatedDirection } from "../MyPage/selectors";

function FAQDetail({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector((state) => state.FAQDetail.components);
  const title = useSelector((state) => state.FAQDetail.title);
  const token = useSelector((state) => state.login.activeToken);
  const heroComp = useSelector(heroComponent());
  const topComponents = useSelector(selectTopComponents());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const titleDoc = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);

  ChangeTitleDoc(subjectCode, aggregatedDirection, titleDoc, path);
  const { status, errorData } = GetStatusAndErrorData("FAQDetail");
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components?.components && (
          <>
            <DetailsHeader
              headerComponents={topComponents}
              goBackText={heroComp?.data.textHref || "TORNA INDIETRO"}
              link={heroComp?.data.link || ""}
              onlyBack={true}
            />
            <LayoutFAQDetail
              heroImg={`${components?.settings?.heroImage}:hero-img?$DEFAULT$`}
              goBackText={heroComp?.data.textHref || "TORNA INDIETRO"}
              link={heroComp?.data.link || ""}
              title={title}
            />
          </>
        )
      )}
    </>
  );
}

export default FAQDetail;
