import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { VideoContainer } from "./style";

//Vigna: ferraristage/f163ab14-0ce6-44cb-943e-23e6ac560ccf-2-AVS
//40 anni: ferraristage/bb4fe7ca-6c12-4267-b964-7c75ec3e2f5b-2-AVS
//Macchina: ferraristage/sample-3-AVS

//  posterimage: "https://www.altrasoluzione.com/images/blog/siti-per-scaricare-immagini-gratis-legalmente/immagini-gratis.jpg",
const SmartCropVideoViewer = ({ data, id, s7VideoConfig }) => {
  const videoContainerRef = useRef(null);
  const viewerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (viewerRef.current) {
      try {
        viewerRef.current.dispose();
        viewerRef.current = null;
      } catch (error) {
        console.error("Error disposing SmartCropVideoViewer:", error);
      }
    }
    // Ensure the container exists
    const container = document.getElementById(`s7smartcropvideo_div-${id}`);
    if (!container) return;

    const config = s7VideoConfig ?? data.s7VideoConfig ?? "SmartCropVideo";
    viewerRef.current = new window.s7viewers.SmartCropVideoViewer({
      containerId: videoContainerRef.current.id,
      params: {
        serverurl: data.serverUrl,
        contenturl: data.contentUrl,
        config: `${data.environment}/${config}`,
        videoserverurl: data.videoServerUrl,
        asset: data.scene7FileAvsPath,
      },
    });

    viewerRef.current.init();

    return () => {
      // Safe disposal when the component unmounts
      if (viewerRef.current) {
        try {
          viewerRef.current.dispose();
          viewerRef.current = null; // Clear reference after disposal
        } catch (error) {
          console.error("Error disposing SmartCropVideoViewer:", error);
        }
      }
    };
  }, [location.pathname]);

  return (
    <VideoContainer
      id={`s7smartcropvideo_div-${id}`}
      ref={videoContainerRef}
    ></VideoContainer>
  );
};

export default React.memo(SmartCropVideoViewer);
