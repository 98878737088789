import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  ul.slider li.slide > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .carousel .control-dots {
    display: flex;
    z-index: 2;
    width: auto;
    align-items: center;
    padding-left: 32px;
    box-sizing: border-box;
    gap: 13px;
    bottom: 32px;
    margin: 0;
  }
  .carousel .control-dots > span {
    margin: 0;
  }
  .carousel .slide {
    opacity: 1 !important;
    z-index: 0 !important;
    opacity: 0 !important;
  }
  .carousel .selected {
    z-index: 1 !important;
    opacity: 1 !important;
  }
  .carousel .control-dots svg.icon {
    display: none;
  }
  .carousel.carousel-slider {
  }
  @media (max-width: 1279px) {
    padding-bottom: 50px;
    .carousel.carousel-slider {
      bottom: 0;
      overflow: initial;
    }
    .carousel .control-dots {
      padding-left: 0;
      bottom: -50px;
    }
    .slider {
      transform: none !important;
    }
  }
  .carousel img {
    width: 100%;
  }
  @media (min-width: 1280px) {
    width: 100%;
    .carousel-custom-controllers {
      bottom: 32px;
    }
    .carousel-custom-controllers path {
      fill: #fff !important;
    }
  }
  .s7smartcropvideoviewer {
    margin: 0;
  }
`;

export const Controllers = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  gap: 25px;
  right: 0;
  z-index: 1;
  @media (min-width: 769px) {
    right: 32px;
  }
`;
export const Icon = styled.div`
  cursor: pointer;
`;

export const CarouselLeft = styled.div`
  position: absolute;
  transform: translate(-9999px, -9999px);
`;

export const CarouselRight = styled.div`
  position: absolute;
  transform: translate(-9999px, -9999px);
`;
