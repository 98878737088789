import { Carousel } from "react-responsive-carousel";
import { useEffect, useRef, useState, useCallback } from "react";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import {
  Wrapper,
  DivSelectedDots,
  ImgDots,
  DivUnselectedDots,
  SvgArrowRight,
  SvgArrowRightPath,
  SvgArrowLeft,
  SvgArrowLeftPath,
} from "./style";

function CarouselAlternative({
  data,
  children,
  centerMode = false,
  fullScreen,
  firstHover,
}) {
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const containerRef = useRef(null);
  const dotsRef = useRef(null);

  // Dopo il mount, assegno a dotsRef l'elemento generato dal Carousel per le preview
  useEffect(() => {
    const controlDots = document.querySelector(".control-dots");
    if (controlDots) {
      controlDots.style.scrollBehavior = "smooth";
      controlDots.style.willChange = "transform";
      dotsRef.current = controlDots;
    }
  }, [data]);

  // Funzione per calcolare dimensioni e scroll massimo
  const getDimensions = useCallback(() => {
    const sliderWidth = containerRef.current
      ? containerRef.current.offsetWidth * 0.8
      : 0;
    const sliderContent = data?.items?.length * 73 - 13;
    const totalScrollable = sliderContent - sliderWidth;
    return { sliderWidth, sliderContent, totalScrollable };
  }, [data?.items?.length]);

  // Aggiorna la visibilità delle frecce della preview
  useEffect(() => {
    const updateScrollState = () => {
      if (!containerRef.current || !dotsRef.current) return;
      const { totalScrollable } = getDimensions();
      setHideLeftArrow(scrollAmount <= 0);
      setHideRightArrow(scrollAmount >= totalScrollable);
    };

    updateScrollState();
    const handleResize = () => updateScrollState();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [scrollAmount, getDimensions]);

  // Funzione di scroll unificata per le preview, con requestAnimationFrame
  const handleScroll = (direction) => {
    if (!dotsRef.current || !containerRef.current) return;
    const { totalScrollable } = getDimensions();
    const scrollStep = Math.min(600, totalScrollable);
    let newScrollAmount =
      direction === "right"
        ? scrollAmount + scrollStep
        : scrollAmount - scrollStep;
    newScrollAmount = Math.max(0, Math.min(newScrollAmount, totalScrollable));

    requestAnimationFrame(() => {
      dotsRef.current.scrollTo({ left: newScrollAmount, behavior: "smooth" });
    });
    setScrollAmount(newScrollAmount);
    setHideLeftArrow(newScrollAmount <= 0);
    setHideRightArrow(newScrollAmount >= totalScrollable);
  };

  // Listener per i tasti freccia per cambiare le slide principali
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        setCurrentSlide((prev) => Math.min(prev + 1, data?.items?.length - 1));
      } else if (event.key === "ArrowLeft") {
        setCurrentSlide((prev) => Math.max(prev - 1, 0));
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [data?.items?.length]);

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    return isSelected ? (
      <DivSelectedDots
        onClick={onClickHandler}
        index={index}
        value={index}
        key={index}
        aria-label={`${label} ${index + 1}`}
      >
        <ImgDots loading="lazy" src={data?.items?.[index]?.fileReference} />
      </DivSelectedDots>
    ) : (
      <DivUnselectedDots
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        aria-label={`${label} ${index + 1}`}
      >
        <ImgDots
          className="carousel-dot"
          loading="lazy"
          src={data?.items?.[index]?.fileReference}
        />
      </DivUnselectedDots>
    );
  };

  return (
    <Wrapper
      ref={containerRef}
      isMobile={isMobile}
      hideLeftArrow={hideLeftArrow}
      hideRightArrow={hideRightArrow}
      firstHover={firstHover}
      fullScreen={fullScreen}
    >
      {!isMobile && (
        <>
          <SvgArrowLeft
            className="leftArrowSlider"
            onClick={() => handleScroll("left")}
          >
            <SvgArrowLeftPath />
          </SvgArrowLeft>
          <SvgArrowRight
            className="rightArrowSlider"
            onClick={() => handleScroll("right")}
          >
            <SvgArrowRightPath />
          </SvgArrowRight>
        </>
      )}
      <Carousel
        centerMode={centerMode}
        showStatus={true}
        showThumbs={false}
        centerSlidePercentage={65}
        animationHandler={"fade"}
        renderIndicator={renderIndicator}
        selectedItem={currentSlide}
        onChange={(index) => setCurrentSlide(index)}
      >
        {children}
      </Carousel>
    </Wrapper>
  );
}

export default CarouselAlternative;
