// components
import React, { useEffect, useState } from "react";
import CarouselAlternative from "./carouselAlternative/CarouselAlternative";
import useMediaQuery from "../../../hooks/useMediaQuery";
// style
import {
  SvgExitFullScreenPath,
  SvgFullScreen,
  SvgFullScreenPath,
  Wrapper,
} from "./style";

function ImageGallery({ data }) {
  const [fullScreen, setFullscreen] = useState(false);
  const [firstHover, setFirstHover] = useState(false);

  const handleFullScreen = () => {
    setFullscreen((prev) => !prev);
  };

  useEffect(() => {
    if (fullScreen) {
      document.body.style.overflow = "hidden";
      document
        .querySelector(".carousel-root")
        ?.setAttribute("style", "width: 100vw");
    } else {
      document.body.style.overflow = "initial";
      document
        .querySelector(".carousel-root")
        ?.setAttribute("style", "width: 100%");
    }
  }, [fullScreen]);

  // Aggiungo l'evento per il tasto ESC
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && fullScreen) {
        setFullscreen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [fullScreen]);

  return (
    data && (
      <Wrapper
        firstHover={firstHover}
        onMouseEnter={() => setFirstHover(true)}
        fullScreen={fullScreen}
        className="containerCarousel"
      >
        <SvgFullScreen
          className="fullscreenSvg"
          fullScreen={fullScreen}
          onClick={handleFullScreen}
        >
          {fullScreen ? (
            <SvgExitFullScreenPath className="fullscreenSvgPath" />
          ) : (
            <SvgFullScreenPath className="fullscreenSvgPath" />
          )}
        </SvgFullScreen>
        <CarouselAlternative
          data={data}
          fullScreen={fullScreen}
          firstHover={firstHover}
        >
          {data?.items?.map((item, ind) => (
            <div key={`img-${ind}`}>
              <img src={`${item.fileReference}?$DEFAULT$`} loading="lazy" />
            </div>
          ))}
        </CarouselAlternative>
      </Wrapper>
    )
  );
}

export default ImageGallery;
