import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  img {
    width: 100%;
  }
  @media (min-width: 1280px) {
    gap: 32px;
    width: 100%;
  }
  @media (max-width: 1279px) {
    gap: ${(props) => props.gap || "0"};
    flex-direction: ${(props) => props.flexDirection || "column-reverse"};
  }
`;
