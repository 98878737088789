import { Container } from "./style";
import React, { useState, useEffect } from "react";

function Image({ data, injectedSmartCrop, template }) {
  const [smartCrop, setSmartCrop] = useState(injectedSmartCrop);

  useEffect(() => {
    if (!injectedSmartCrop) {
      switch (template) {
        case "news-detail":
          setSmartCrop("news-img");
          break;
        case "welfare-detail":
          setSmartCrop("welfare-img");
          break;
      }
    }
  }, [injectedSmartCrop, template]);

  const url = smartCrop
    ? `${data?.fileReference}:${smartCrop}?$DEFAULT$`
    : `${data?.fileReference}?$DEFAULT$`;

  return (
    <Container>
      <img src={url} alt={data.alt} />
    </Container>
  );
}

export default Image;
