import { Carousel } from "react-responsive-carousel";
import CtaIcon from "../ctaIcon/CtaIcon";
import {
  Wrapper,
  Controllers,
  Icon,
  CarouselLeft,
  CarouselRight,
} from "./style";
import LeftIcon from "../../assets/left";
import RightIcon from "../../assets/right";
import { useState, useEffect, useRef } from "react";
import StopIcon from "../../assets/stop";
import PlayIcon from "../../assets/play";

function CarouselWrapper({
  children,
  centerMode = false,
  itemsLength,
  intervals,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const loader = useRef(null);

  const [isPaused, setIsPaused] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const timeoutRef = useRef(null);
  const startTimeRef = useRef(null);

  const clearExistingTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const itemsInterval =
    intervals ?? Array.from({ length: itemsLength }, () => 6000);

  useEffect(() => {
    if (!isPaused) {
      const intervalTime = remainingTime ?? itemsInterval[currentIndex];
      startTimeRef.current = Date.now();

      timeoutRef.current = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % itemsLength);
        setRemainingTime(null);
      }, intervalTime);
    }

    return clearExistingTimeout;
  }, [currentIndex, isPaused, remainingTime, itemsLength]);

  const togglePauseResume = () => {
    if (!isPaused) {
      const elapsedTime = Date.now() - startTimeRef.current;
      const intervalTime = remainingTime ?? itemsInterval[currentIndex];
      setRemainingTime(intervalTime - elapsedTime);
    }
    setIsPaused((prev) => !prev);
    clearExistingTimeout();
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    const defStyle = {
      color: "white",
      cursor: "pointer",
      width: "8px",
      height: "8px",
    };
    return itemsLength > 1 ? (
      isSelected ? (
        <CtaIcon
          onClickHandler={onClickHandler}
          index={index}
          value={index}
          key={index}
          label={label}
          style={defStyle}
          inset={"-2px"}
          strokeWidth={"2px"}
          animationCountDown={true}
          animationCountDownTime={`${Math.ceil(
            itemsInterval[currentIndex] / 1000
          )}s`}
        ></CtaIcon>
      ) : (
        <span
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
          aria-label={`${label} ${index + 1}`}
          style={{
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <span
            className="carousel-dot"
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "100%",
              backgroundColor: "var(--carousel-dot-color)",
            }}
          ></span>
        </span>
      )
    ) : (
      <></>
    );
  };

  return (
    <Wrapper ref={loader}>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        selectedItem={currentIndex}
        onChange={(index) => setCurrentIndex(index)}
        autoPlay={false}
        infiniteLoop={false}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        stopOnHover={false}
        centerMode={centerMode}
        centerSlidePercentage={65}
        transitionTime={1000}
        renderIndicator={renderIndicator}
        animationHandler={"fade"}
        renderArrowPrev={(onClickHandler, hasPrev, label) => (
          <CarouselLeft
            className="carousel-arrow-left"
            onClick={onClickHandler}
          >
            <LeftIcon />
          </CarouselLeft>
        )}
        renderArrowNext={(onClickHandler, hasNext, label) => (
          <CarouselRight
            className="carousel-arrow-right"
            onClick={onClickHandler}
          >
            <RightIcon />
          </CarouselRight>
        )}
      >
        {children}
      </Carousel>
      {itemsLength > 1 ? (
        <Controllers className="carousel-custom-controllers">
          <Icon
            role="button"
            id="togglePlayBtn"
            tabIndex="0"
            aria-label="stop"
            onClick={() => {
              const btn = document.getElementById("togglePlayBtn");
              isPaused
                ? btn.setAttribute("aria-label", "play")
                : btn.setAttribute("aria-label", "stop");
              togglePauseResume();
              const dots = loader.current.querySelectorAll(
                ".control-dots circle"
              );
              dots?.forEach((v) => {
                v.classList.toggle("paused");
              });
            }}
          >
            {isPaused ? <PlayIcon /> : <StopIcon />}
          </Icon>
          <Icon
            role="button"
            tabIndex={0}
            aria-label="back"
            onClick={() => {
              setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? itemsLength - 1 : prevIndex - 1
              );
            }}
          >
            <LeftIcon />
          </Icon>
          <Icon
            role="button"
            tabIndex={0}
            aria-label="next"
            onClick={() => {
              setCurrentIndex((prevIndex) =>
                prevIndex === itemsLength - 1 ? 0 : prevIndex + 1
              );
            }}
          >
            <RightIcon />
          </Icon>
        </Controllers>
      ) : null}
    </Wrapper>
  );
}

export default CarouselWrapper;
