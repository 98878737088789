import { Container, Image } from "./style";
import { useEffect, useState } from "react";

function EventImage({ elementData }) {
  const url = `${elementData?.fileReference}?$DEFAULT$`;

  return (
    <Container>
      <Image src={url} />
    </Container>
  );
}

export default EventImage;
