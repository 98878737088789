import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
// style
import { Container } from "./style";

// components
import EventDivider from "../../event/eventDetails/twoColumnContainer/leftColumnContainer/eventDivider/EventDivider";
import FiText from "./fi-text/FiText";
import FiButton from "./fi-button/FiButton";
import FiTitle from "./fi-title/FiTitle";
import Image from "../../Image/Image";
import NewsDocContainer from "./newsDocContainer/NewsDocContainer";
import Wrapper from "./divider/Divider";
import FaqAccordionList from "./faqAccordionList/faqAccordionList";
import StrumentiTitle from "../../strumenti/strumentiTitle/StrumentiTitle";
import MainCarousel from "../../home/mainCarousel/MainCarousel";
import FormazioneManagerialeBoxes from "./formazioneManagerialeBoxes/formazioneManagerialeBoxes";
import Tags from "./fi-welfare-tag/Tags";
import HtmlBox from "../../htmlBox/htmlBox";
import VideoPlayerSelector from "../../videoPlayerSelector/VideoComponent";
import { AudioPlayer } from "../../audioWave/AudioPlayer";
import ImageGallery from "../../ImageGallery/ImageGallery";

function LeftColumnContainer({ leftColumnComponents, template }) {
  const isWelfareDetail = useLocation().pathname.includes("welfare-and-benefit")
    ? true
    : false;
  //  •	Text
  // •	Title
  // •	Title Description
  // •	Button
  // •	Carousel
  // •	Divider
  // •	Divider Gray
  // •	Documents
  // •	Image
  // •	Thron Universal Player
  // •	Formazione Manageriale
  // •	FAQ Accordion List

  const componentsMapping = {
    "fi-text": FiText,
    "fi-title": FiTitle,
    "fi-tools-title-description": StrumentiTitle,
    "fi-button": FiButton,
    "fi-carousel": MainCarousel,
    "fi-image-gallery": ImageGallery,
    "fi-video": VideoPlayerSelector,
    "fi-audio": AudioPlayer,
    "fi-divider": EventDivider,
    "fi-welfare/fi-welfare-divider": Wrapper,
    "fi-contratto-documents": NewsDocContainer,
    "fi-image": Image,
    "fi-formazione-manageriale": FormazioneManagerialeBoxes,
    "fi-welfare/fi-welfare-tags": Tags,
    "fi-faq-accordion-list": FaqAccordionList,
    "fi-html-box": HtmlBox,
  };

  return (
    <Container>
      {leftColumnComponents?.map((element, idx) => {
        const ReactGenericComponent = componentsMapping[element.resourceType];
        return (
          ReactGenericComponent && (
            <ReactGenericComponent
              id={idx}
              key={`${element.resourceType}-${idx}`}
              data={element.data}
              template={isWelfareDetail ? "welfare-detail" : template}
            />
          )
        );
      })}
    </Container>
  );
}

LeftColumnContainer.propTypes = {
  leftColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default LeftColumnContainer;
