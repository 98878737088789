import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1280px) {
    width: 384px;
    box-sizing: border-box;
    min-width: 384px;
  }
`;

export const InfoRecapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  background: var(--alternative-bg-color);
  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 5px;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 100%;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  gap: 22px;
  width: 100%;
`;

export const Label = styled.span`
  &:first-of-type {
    margin-top: 0px;
  }
  margin-top: 32px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 12px;
`;

export const Content = styled.span`
  font-size: 13px;
  line-height: 20px;
`;

export const Category = styled.span`
  font-size: 9px;
  line-height: 20px;
  text-transform: uppercase;
  color: #707070;
`;
