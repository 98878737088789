import VideoPlayer from "./videoPlayer/VideoPlayer";
import SmartCropVideoViewer from "./smartCropVideoViewer/smartCropVideoViewer";

function VideoPlayerSelector({ data, id, s7VideoConfig }) {
  return data?.fileReference?.includes("scene7.com") &&
    data.scene7FileAvsPath &&
    !data.error ? (
    <SmartCropVideoViewer data={data} id={id} s7VideoConfig={s7VideoConfig} />
  ) : (
    <VideoPlayer data={data} />
  );
}

export default VideoPlayerSelector;
