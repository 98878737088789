import {
  Container,
  TitleForm,
  SendButton,
  SectionForm,
  TextDescr,
  EventTitle,
  GenericTitle,
  EventDescr,
} from "./../../style";

import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetUserRegistration } from "../../../../../pages/SimpleEventsSubscribeForm/action";
import { setBodyPayload } from "../../../../../pages/SimpleEventsSubscribeForm/SimpleEventsSubscribeFormSlice";
//components
import ComponentPrivacy from "./../ComponentPrivacy/ComponentPrivacy";
import ErrorField from "./../ErrorField/ErrorField";
import RadioButtonInput from "./RadioButtonInput";
import SelectInput from "./SelectInput";
import { isEqual } from "lodash";

function LayoutSubscriptionForm({ data }) {
  const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState(false);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState(null);

  //selectors
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const bodyPayload = useSelector(
    (state) => state.SimpleEventsSubscribeForm.defaultBodyPayload
  );
  const savedBodyPayload = useSelector(
    (state) => state.SimpleEventsSubscribeForm.savedBodyPayload
  );
  const thereIsSubscription = useSelector(
    (state) => state.SimpleEventsSubscribeForm.thereIsSubscription
  );
  const checkedConsents = useSelector(
    (state) => state.SimpleEventsSubscribeForm.checkedConsents
  );
  const myPagePath = headerLinks?.find((v) => v.template === "my-page")?.path;

  const allEvents = data?.eventItems?.items?.map((event) => event);
  const AppointmentWithAvailablePlaces = allEvents?.map((singleEvent) =>
    singleEvent.appointments.find(
      (el) => el.availablePlacesFrontEnd - el.takenSeats >= 1
    )
  );

  function generateHtml(argument) {
    return { __html: argument };
  }

  const handleEventInfo = (e, code, consents) => {
    const { value } = e.currentTarget;

    let dateRegistered = bodyPayload?.events?.filter(
      (event) => event?.eventCode === code
    );
    let subscription = bodyPayload?.events?.filter(
      (event) => event?.eventCode !== code && event
    );
    const spreadObject = dateRegistered ? subscription : bodyPayload.events;
    dispatch(
      setBodyPayload({
        ...bodyPayload,
        events: [
          ...spreadObject,
          {
            eventCode: code,
            consents: consents,
            appointmentId: +value,
            NumGuests: 0,
          },
        ],
      })
    );
  };

  const getAppointmentHours = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const showTextOnInput = (event, appointment) =>
    event?.pickSlotTitle === "false"
      ? getAppointmentHours(
          appointment?.appointmentStartDate,
          appointment?.appointmentEndDate,
          lang
        )
      : appointment?.titleTxt;

  const isSubmitActive =
    (!data?.policiesRules?.items ||
      (checkedConsents.length === data?.policiesRules?.items?.length &&
        !checkedConsents?.find((el) => el.checked === false))) &&
    bodyPayload?.events?.length > 0 &&
    !isEqual(bodyPayload, savedBodyPayload);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setButtonHasBeenClicked(true);
    if (isSubmitActive) {
      setAllowed(true);
      dispatch(
        SetUserRegistration({
          responseBody: bodyPayload,
          myPagePath: myPagePath,
          token,
          lang,
        })
      );
    } else {
      setAllowed(false);
    }
  };

  useEffect(() => {
    if (AppointmentWithAvailablePlaces) {
      const element =
        document.getElementById("required")?.previousElementSibling;
      if (buttonHasBeenClicked) {
        element &&
          !allowed &&
          element?.scrollIntoView({ behavior: "smooth", block: "center" });
        setButtonHasBeenClicked(false);
      }
    }
  }, [
    buttonHasBeenClicked,
    bodyPayload,
    AppointmentWithAvailablePlaces,
    dispatch,
    allowed,
  ]);

  return (
    <>
      <TitleForm>{data?.eventTitleDescr?.title}</TitleForm>
      <GenericTitle>
        <p
          dangerouslySetInnerHTML={generateHtml(
            data?.eventTitleDescr?.description
          )}
        />
      </GenericTitle>
      <Container>
        <form
          onSubmit={(e) => {
            onSubmitHandler(e);
          }}
          ref={formRef}
        >
          {data?.eventItems?.items?.map((event, i) => (
            <SectionForm key={`event - ${i}`}>
              <EventTitle>{event?.title}</EventTitle>
              {event?.richText && (
                <EventDescr
                  dangerouslySetInnerHTML={generateHtml(event?.richText)}
                />
              )}
              {event?.richText && <h4>{event?.slotLabel}</h4>}
              {event?.slotText && <TextDescr>{event?.slotText}</TextDescr>}
              {event?.radioButtonLayout === "true" ? (
                <RadioButtonInput
                  data={event}
                  bodyPayload={bodyPayload}
                  showTextOnInput={showTextOnInput}
                  handleEventInfo={handleEventInfo}
                />
              ) : (
                <SelectInput
                  data={event}
                  showTextOnInput={showTextOnInput}
                  handleEventInfo={handleEventInfo}
                />
              )}
              {!bodyPayload?.events?.length && allowed === false && (
                <ErrorField text={event?.errorMessage} />
              )}
            </SectionForm>
          ))}
          <ComponentPrivacy data={data} allowed={allowed} />
          <SendButton
            className={
              isSubmitActive ? "submitButton activeSubmit" : "submitButton"
            }
          >
            {thereIsSubscription
              ? data?.button?.modifyLbl
              : data?.button?.subscribeLbl}
          </SendButton>
        </form>
      </Container>
    </>
  );
}

export default LayoutSubscriptionForm;
