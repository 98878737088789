import { HeroWrapper, HeroInfo, HeroSubTitle, HeroTitle } from "./style";

export default function Header({ data }) {
  return (
    <HeroWrapper>
      <img src={`${data?.fileReference}:hero-img?$DEFAULT$`} />
      <HeroInfo>
        <HeroTitle>{data?.title}</HeroTitle>
        <HeroSubTitle>{data?.subTitle}</HeroSubTitle>
      </HeroInfo>
    </HeroWrapper>
  );
}
