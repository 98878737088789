import React from "react";
import { ButtonContainer, CtaButton, CtaContainerButton } from "./style";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function ButtonsCta({ data, isEventClosed }) {
  const { language } = useSelector((state) => state.coreComponents);

  const setUrl = (url) => {
    let result = url;
    if (url) {
      if (url.includes("news-and-events/events/")) {
        const fixed = "/event-details?path=";
        const splittedUrl = url.split(`${language}/`);
        result = fixed + `/${language}/` + splittedUrl[1];
      }
    }
    return result;
  };

  return (
    <ButtonContainer isClosed={isEventClosed}>
      {!isEventClosed && (
        <Link to={"/generic-subscription-form?path=" + data?.leftBtn?.link}>
          <CtaButton
            color="var(--main-color)"
            bg="transparent"
            border="1px solid #d4d4d4"
          >
            {data?.leftBtn?.title}
          </CtaButton>
        </Link>
      )}
      <CtaContainerButton>
        <Link
          style={{ pointerEvents: !data?.rightBtn?.link ? "none" : "pointer" }}
          to={setUrl(data?.rightBtn?.link)}
        >
          <CtaButton>{data?.rightBtn?.title}</CtaButton>
        </Link>
      </CtaContainerButton>
    </ButtonContainer>
  );
}
