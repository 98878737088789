import styled from "styled-components";

export const VideoPlayerWrapper = styled.div`
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const PlayTextContainer = styled.div`
  ${(props) => (props.show === true ? `opacity: 1` : `opacity: 0`)};
  display: flex;
  flex-direction: column;
  position: relative;
  top: 100px;
  margin: auto;
  padding: 5px 15px;
  background: #00000036;
  width: 95%;
`;

export const AltText = styled.p`
  height: 18px;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 5px;
  text-transform: uppercase;
  color: white;
`;
export const Caption = styled.p`
  color: white;
  height: 18px;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 5px;
`;
