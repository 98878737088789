import md5 from "md5";

const CONSULTANT_DIRECTION = "consultant";

export const ChangeTitleDoc = (
  subjectCode,
  aggregatedDirection,
  title,
  path,
  setManually
) => {
  title = title?.trim();

  //gestione consulenti, per loro si manda come direction consultant
  if (subjectCode.startsWith("E_")) {
    aggregatedDirection = CONSULTANT_DIRECTION;
  }

  if (setManually && title && title !== document.title) {
    SwitchTitle(subjectCode, aggregatedDirection, title, path);
  } else if (
    title &&
    title !== document.title &&
    window.location.href.includes(path)
  ) {
    SwitchTitle(subjectCode, aggregatedDirection, title, path);
  }
};

//Funzione che scrive i dati che inviamo ad analytics
//in local storage puramente per debug
const pushToLocalStorage = (item) => {
  if (
    process.env.REACT_APP_ENV === "local" ||
    (process.env.REACT_APP_ENV === "production" &&
      (item.id === "0000000000010160" || item.id === "E_00000005959"))
  ) {
    let dataLayerLog = window.localStorage.getItem("dataLayerLog") || "";
    dataLayerLog = dataLayerLog.replace(/[\[\]]/g, "");
    dataLayerLog = dataLayerLog
      ? dataLayerLog + ", " + JSON.stringify(item)
      : JSON.stringify(item);
    window.localStorage.setItem("dataLayerLog", "[" + dataLayerLog + "]");
  }
};

const SwitchTitle = (subjectCode, aggregatedDirection, title, path) => {
  document.title = title;

  // const page_date = new Date(Date.now())?.toLocaleString("it-IT", {
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   second: "2-digit",
  // });

  const default_obj = {
    title: title,
    event: "content_loaded",
    id: md5(subjectCode),
    department: aggregatedDirection,
    path: path,
    url: window.location.href,
  };

  //DEBUG ANALYTICS - SCOMMENTARE AL BISOGNO
  // const debugItem = {
  //   title: title,
  //   path: path,
  //   url: window.location.href,
  //   id: subjectCode,
  //   department: aggregatedDirection,
  //   date: page_date,
  // };

  // pushToLocalStorage(debugItem);

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(default_obj);
};

export const ChangeTitlePublicDoc = (title, path) => {
  if (
    title &&
    title !== document.title &&
    window.location.href.includes(path)
  ) {
    document.title = title;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "content_loaded",
    });
  }
};
